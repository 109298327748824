/* nunito-200 - latin */
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 200;
    src: local(""), url("/fonts/nunito-v20-latin-200.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/nunito-v20-latin-200.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-300 - latin */
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 300;
    src: local(""), url("/fonts/nunito-v20-latin-300.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/nunito-v20-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-regular - latin */
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    src: local(""), url("/fonts/nunito-v20-latin-regular.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/nunito-v20-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-500 - latin */
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 500;
    src: local(""), url("/fonts/nunito-v20-latin-500.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/nunito-v20-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-600 - latin */
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    src: local(""), url("/fonts/nunito-v20-latin-600.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/nunito-v20-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-700 - latin */
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    src: local(""), url("/fonts/nunito-v20-latin-700.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/nunito-v20-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-800 - latin */
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 800;
    src: local(""), url("/fonts/nunito-v20-latin-800.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/nunito-v20-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-900 - latin */
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 900;
    src: local(""), url("/fonts/nunito-v20-latin-900.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/nunito-v20-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-200italic - latin */
@font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: 200;
    src: local(""), url("/fonts/nunito-v20-latin-200italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/nunito-v20-latin-200italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-300italic - latin */
@font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: 300;
    src: local(""), url("/fonts/nunito-v20-latin-300italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/nunito-v20-latin-300italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-italic - latin */
@font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: 400;
    src: local(""), url("/fonts/nunito-v20-latin-italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/nunito-v20-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-500italic - latin */
@font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: 500;
    src: local(""), url("/fonts/nunito-v20-latin-500italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/nunito-v20-latin-500italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-600italic - latin */
@font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: 600;
    src: local(""), url("/fonts/nunito-v20-latin-600italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/nunito-v20-latin-600italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-700italic - latin */
@font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: 700;
    src: local(""), url("/fonts/nunito-v20-latin-700italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/nunito-v20-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-800italic - latin */
@font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: 800;
    src: local(""), url("/fonts/nunito-v20-latin-800italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/nunito-v20-latin-800italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-900italic - latin */
@font-face {
    font-family: "Nunito";
    font-style: italic;
    font-weight: 900;
    src: local(""), url("/fonts/nunito-v20-latin-900italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/nunito-v20-latin-900italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* m-plus-rounded-1c-100 - latin */
@font-face {
    font-family: "M PLUS Rounded 1c";
    font-style: normal;
    font-weight: 100;
    src: local(""), url("/fonts/m-plus-rounded-1c-v10-latin-100.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/m-plus-rounded-1c-v10-latin-100.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* m-plus-rounded-1c-regular - latin */
@font-face {
    font-family: "M PLUS Rounded 1c";
    font-style: normal;
    font-weight: 400;
    src: local(""), url("/fonts/m-plus-rounded-1c-v10-latin-regular.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/m-plus-rounded-1c-v10-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* m-plus-rounded-1c-300 - latin */
@font-face {
    font-family: "M PLUS Rounded 1c";
    font-style: normal;
    font-weight: 300;
    src: local(""), url("/fonts/m-plus-rounded-1c-v10-latin-300.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/m-plus-rounded-1c-v10-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* m-plus-rounded-1c-500 - latin */
@font-face {
    font-family: "M PLUS Rounded 1c";
    font-style: normal;
    font-weight: 500;
    src: local(""), url("/fonts/m-plus-rounded-1c-v10-latin-500.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/m-plus-rounded-1c-v10-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* m-plus-rounded-1c-800 - latin */
@font-face {
    font-family: "M PLUS Rounded 1c";
    font-style: normal;
    font-weight: 800;
    src: local(""), url("/fonts/m-plus-rounded-1c-v10-latin-800.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/m-plus-rounded-1c-v10-latin-800.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* m-plus-rounded-1c-700 - latin */
@font-face {
    font-family: "M PLUS Rounded 1c";
    font-style: normal;
    font-weight: 700;
    src: local(""), url("/fonts/m-plus-rounded-1c-v10-latin-700.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/m-plus-rounded-1c-v10-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* m-plus-rounded-1c-900 - latin */
@font-face {
    font-family: "M PLUS Rounded 1c";
    font-style: normal;
    font-weight: 900;
    src: local(""), url("/fonts/m-plus-rounded-1c-v10-latin-900.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/m-plus-rounded-1c-v10-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-100 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 100;
    src: local(""), url("/fonts/roboto-v29-latin-100.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/roboto-v29-latin-100.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-100italic - latin */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 100;
    src: local(""), url("/fonts/roboto-v29-latin-100italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/roboto-v29-latin-100italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-300 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    src: local(""), url("/fonts/roboto-v29-latin-300.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/roboto-v29-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-300italic - latin */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 300;
    src: local(""), url("/fonts/roboto-v29-latin-300italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/roboto-v29-latin-300italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-regular - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: local(""), url("/fonts/roboto-v29-latin-regular.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/roboto-v29-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-italic - latin */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 400;
    src: local(""), url("/fonts/roboto-v29-latin-italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/roboto-v29-latin-italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500italic - latin */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 500;
    src: local(""), url("/fonts/roboto-v29-latin-500italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/roboto-v29-latin-500italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-500 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    src: local(""), url("/fonts/roboto-v29-latin-500.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/roboto-v29-latin-500.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700italic - latin */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 700;
    src: local(""), url("/fonts/roboto-v29-latin-700italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/roboto-v29-latin-700italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-700 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    src: local(""), url("/fonts/roboto-v29-latin-700.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/roboto-v29-latin-700.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-900 - latin */
@font-face {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 900;
    src: local(""), url("/fonts/roboto-v29-latin-900.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/roboto-v29-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* roboto-900italic - latin */
@font-face {
    font-family: "Roboto";
    font-style: italic;
    font-weight: 900;
    src: local(""), url("/fonts/roboto-v29-latin-900italic.woff2") format("woff2"),
        /* Chrome 26+, Opera 23+, Firefox 39+ */ url("/fonts/roboto-v29-latin-900italic.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
